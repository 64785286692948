import React from 'react'
import './about.css'

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container">
          <div className="about__content">
                <p>My name is Zachary Keller or Zach.  I am a front-end developer, born and raised in Tampa, Florida with a passion for expanding
                   my skills and expertise as a developer. When I'm not coding I am hanging out with family, reading, or playing FPS games.
                </p>
              <div className="btn__about">
                <a href="#contact" className="btn btn-primary">Let's Talk</a>
              </div>
            </div>
        </div>
    </section>
  )
}

export default About